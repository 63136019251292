/* eslint-env jquery */

'use strict';

jQuery( function( $ ) {

	lightbox.option( {
		'disableScrolling': true,
		'showImageNumberLabel': false,
	} );

	$('.slider-media').owlCarousel( {
		loop     		: true,
		margin   		: 0,
		items    		: 1,
		dots   	 		: false,
		autoplay 		: true,
		autoplayTimeout : 7000,
		autoplaySpeed   : 2000,
		navSpeed		: 2000,
		responsive      : {
			0: {
				nav   : false,
			},
			992: {
				nav    : true,
			},
		},
	} );

	$('.slider-3col').owlCarousel( {
		loop     		: true,
		margin   		: 15,
		dots   	 		: false,
		navSpeed		: 2000,
		responsive      : {
			0: {
				items : 2,
				nav   : false,
			},
			992: {
				items  : 3,
				nav    : true,
			},
		},
	} );

	$('.slider-4col').owlCarousel( {
		loop     		: true,
		margin   		: 15,
		items    		: 4,
		dots   	 		: false,
		navSpeed		: 2000,
		responsive      : {
			0: {
				items : 2,
				nav   : false,
			},
			992: {
				items  : 4,
				nav    : true,
			},
		},
	} );

	/* Buttons */

	$( document ).on( 'click', '#showMobileMenu', function( event ) {
		event.preventDefault();
		$( '.nav-mobile' ).animate( { "left":"0px" }, "slow").addClass( 'visible' );
	} );

	$( document ).on( 'click', '.has-children', function( event ) {
		event.preventDefault();
		$(this).children( '.nav-list' ).animate( { "left":"0px" }, "slow").addClass( 'visible' );
	} );

	$( document ).on( 'click', '#hideMobileMenu', function( event ) {
		event.preventDefault();
		$('.visible').animate( { "left":"-100vw" }, "slow").removeClass( 'visible' );
	} );

	$( document ).on( 'click', '#backMobileMenu', function( event ) {
		event.preventDefault();
		const stack = $( '.visible' );
		const len = stack.length;

		if ( stack.length > 1 ) {
			const top = stack[ len - 1 ];
			$( top ).animate( { "left":"-100vw" }, "slow").removeClass( 'visible' );
		} else {
			$( stack ).animate( { "left":"-100vw" }, "slow").removeClass( 'visible' );
		}
	} );

	$( document ).on( 'click', '#showOverview', function( event ) {
		event.preventDefault();
		$( '#cartOverview' ).animate( { "right":"0px" }, "slow" ).addClass( 'visible' );

	} );

	$( document ).on( 'click', '#hideOverview', function( event ) {
		event.preventDefault();
		$( '#cartOverview' ).animate( { "right":"-600px" }, "slow").removeClass( 'visible' );
	} );

	$( document ).on( 'click', '#showSearchForm', function( event ) {
		event.preventDefault();
		$( '#searchForm' ).animate( { "left":"0px" }, "slow").addClass( 'visible' );

	} );

	$( document ).on( 'click', '#hideSearchForm', function( event ) {
		event.preventDefault();
		$( '#searchForm' ).animate( { "left":"-100vw" }, "slow" ).removeClass( 'visible' );

	} );

	$( document ).on( 'click', '#showFilters', function( event ) {
		event.preventDefault();
		$( '#shopFilters' ).animate( { "left":"0px" }, "slow").addClass( 'visible' );

	} );

	$( document ).on( 'click', '#hideFilters', function( event ) {
		event.preventDefault();
		$( '#shopFilters' ).animate( { "left":"-100vw" }, "slow" ).removeClass( 'visible' );

	} );

	$( document ).on( 'click', '.button-close', function() {
		$( this ).parent().fadeOut();
	} );

	$( document ).on( 'click', '.button-close', function() {
		$( this ).parent().fadeOut();
	} );

	$( document ).on( 'click', '#decQty', function() {
		const val = parseInt( $( '#quantity' ).val() );
		const min = parseInt( $( '#quantity' ).attr('min') );
		if ( val > min ) {
			$( '#quantity' ).val( ( val - 1 ) );
		}
	} );

	$( document ).on( 'click', '#incQty', function() {
		const val = parseInt( $( '#quantity' ).val() );
		const max = parseInt( $( '#quantity' ).attr('max') );
		if ( val < max ) {
			$( '#quantity' ).val( ( val + 1 ) );
		}
	} );

	/* Expandable  / Collapsible items */
	if ( $( '.show' ).length ) {
		$( '.show' ).next().slideDown( '350'  );
	}

	$( document ).on( 'click', '.toggler', function( event ) {
		event.preventDefault();

	    const element = $( this );

	    if ( element.hasClass( 'show' ) ) {
	        element.removeClass( 'show' );
	        element.next().slideUp( 350 );
	    } else {
			if ( $( '.show' ).length ) {
				$('.show').next().slideUp('350');
				$('.show').removeClass( 'show' );
			}
			element.addClass( 'show' );
			element.next().slideToggle( 350 );
		}
	} );

	/* Price range & number */
	if ( $( '#rangeMin' ).length && $( '#rangeMax' ).length && $( '#rangeMinValue' ).length && $( '#rangeMaxValue' ).length) {

		$( '#rangeMinValue' ).html( $( '#rangeMin' ).val() );
		$( '#rangeMaxValue' ).html( $( '#rangeMax' ).val() );

		$( document ).on( 'input', '#rangeMin', function( event ) {
			$( '#rangeMinValue' ).html( $(this).val() );
		} );

		$( document ).on( 'input', '#rangeMax', function( event ) {
			$( '#rangeMaxValue' ).html( $(this).val() );
		} );
	}

	$( document ).on( 'click', '.thumb-link', function( event ) {
		event.preventDefault();
		const target = '#' + $( this ).attr( 'href');

		$('.image-item').hide();
		$( target ).fadeIn();
	} );


} );
